import React from 'react';
import { Button, Flex, Text, EntityList } from '@contentful/f36-components';

import { ICategoryProps } from './ICategory';

const Category = ({ category, onCategoryClick, selectedApiCategoryId, isBordered }: ICategoryProps) => {
	const getCssButtonClasses = () => {
		let cssClasses = 'align-self-center';

		if (selectedApiCategoryId === category.id) {
			cssClasses += ' list-item-active';
		}

		return cssClasses;
	};

	return (
		<>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				gap="spacingS"
				marginTop="spacingS"
				marginBottom={isBordered ? "spacingS" : "spacingM"}
				paddingBottom={isBordered ? "spacingS" : undefined}
				className={isBordered ? "bordered-bottom" : undefined}
			>
				<EntityList.Item
					key={category.id}
					title={category.name?.default || ''}
					contentType={category.id}
					withThumbnail={false}
					thumbnailUrl={''}
					status={category.online ? 'published' : 'archived'}
					className='category-list-item'
				/>
				{category.online && <Button
					variant="secondary"
					size="small"
					className={getCssButtonClasses()}
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.stopPropagation();
						onCategoryClick(category);
					}}
				>
					Select
				</Button>}
			</Flex>
		</>
	);
};

export default Category;
