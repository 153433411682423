import React from 'react';
import { List } from '@contentful/f36-components';
import { RefinementValue } from './Subcomponents';

import { ShopperSearchTypes } from 'commerce-sdk-isomorphic';
import { IRefinementProps } from './IRefinement';

const Refinement = ({ refinement, selectedRefinementValues, onRefinementSelect, isDisabled }: IRefinementProps) => {
	const onRefinementValueClick = (refinementValue: ShopperSearchTypes.ProductSearchRefinementValue) => {
		onRefinementSelect(refinement, refinementValue);
	};

	if (!refinement.values) {
		return null;
	}

	return (
		<List.Item key={refinement.attributeId}>
			<List className="refinements-list">
				{refinement.values.map((refinementValue) => {
					return (
						<RefinementValue
							key={refinementValue.value}
							refinementValue={refinementValue}
							selectedRefinementValues={selectedRefinementValues}
							onRefinementValueClick={onRefinementValueClick}
							isDisabled={isDisabled}
						/>
					);
				})}
			</List>
		</List.Item>
	);
};

export default Refinement;
