// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Spinner, Autocomplete, Pagination, Box, Text, Select, Flex } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';
import { ProductHitsList, SortingOptions, Refinements, SelectedRefinements } from './Subcomponents';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { css } from 'emotion';

import type { ShopperSearchTypes, ShopperProductsTypes } from 'commerce-sdk-isomorphic';
import { IProductDialogProps, ISelectedRefinements } from './IProductDialog';

const DEFAULT_REFINEMENTS: ISelectedRefinements = {
	cgid: [
		{
			label: '',
			value: 'root',
			hitCount: 0,
		},
	],
};

let debounce: NodeJS.Timeout | undefined;

const ProductDialog = ({ apiClient }: IProductDialogProps) => {
	const sdk = useSDK<DialogExtensionSDK>();
	const [apiProduct, setApiProduct] = useState<ShopperProductsTypes.Product | null>(null);
	const [isApiProductLoading, setIsApiProductLoading] = useState<boolean>(false);
	const [productSearchResult, setProductSearchResult] = useState<ShopperSearchTypes.ProductSearchResult | null>(null);
	const [isProductSearchResultLoading, setIsProductSearchResultLoading] = useState<boolean>(false);
	const [query, setQuery] = useState<string>('');
	const [selectedRefinements, setSelectedRefinements] = useState<ISelectedRefinements>(DEFAULT_REFINEMENTS);
	const [selectedSortingOption, setSelectedSortingOption] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [limit, setLimit] = useState<number>(12);
	const autoCompleteRef = useRef('');
	const [siteId, setSiteId] = useState<string>(sdk.parameters.installation?.openCommerceAPI?.siteId);
	const siteListArray = sdk.parameters.installation?.sitesList.split(',').map((site: string) => site.trim()) || [];

	useEffect(() => {
		(async () => {
			setIsProductSearchResultLoading(true);
			setApiProduct(null);
			apiClient.setSiteId(siteId);

			let productSearchResult: ShopperSearchTypes.ProductSearchResult | null = null;

			try {
				productSearchResult = await apiClient.searchProducts(
					query,
					selectedRefinements,
					selectedSortingOption,
					limit,
					page * limit
				);
			} catch (e: any) {
				sdk.notifier.error(`Failed to get products from SFCC due to the error: ${e.message}`);
			}

			setProductSearchResult(productSearchResult);
			setIsProductSearchResultLoading(false);
		})();
	}, [sdk.notifier, apiClient, query, selectedRefinements, selectedSortingOption, page, limit, siteId]);

	useEffect(() => {
		// to restrict query parameter length
		if (autoCompleteRef.current) {
			autoCompleteRef.current.setAttribute('maxlength', 50)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoCompleteRef.current])

	const onProductHitClick = async (productHit: ShopperSearchTypes.ProductSearchHit) => {
		setIsApiProductLoading(true);

		let product: ShopperProductsTypes.Product | null = null;

		try {
			product = await apiClient.getProduct(productHit.productId);
		} catch (e: any) {
			sdk.notifier.error(`Failed to select product due to the error: ${e.message}`);
		}

		setApiProduct(product);
		setIsApiProductLoading(false);
	};

	const onSearchProductValueChange = (value: string) => {
		setPage(0);

		if (debounce) {
			clearTimeout(debounce);
		}

		debounce = setTimeout(() => {
			setQuery(value);
		}, 300);
	};

	const onViewPerPageChange = (itemsPerPageNumber: number) => {
		setPage(Math.floor((limit * page + 1) / itemsPerPageNumber));
		setLimit(itemsPerPageNumber);
	};

	const onRefinementSelect = (
		refinement: ShopperSearchTypes.ProductSearchRefinement,
		refinementValue: ShopperSearchTypes.ProductSearchRefinementValue
	) => {
		const newSelectedRefinements: ISelectedRefinements = { ...selectedRefinements };

		if (selectedRefinements[refinement.attributeId]) {
			if (refinement.attributeId === 'cgid' || refinement.attributeId === 'price') {
				newSelectedRefinements[refinement.attributeId] = [refinementValue];
			} else {
				newSelectedRefinements[refinement.attributeId].push(refinementValue);
			}
		} else {
			newSelectedRefinements[refinement.attributeId] = [refinementValue];
		}

		setSelectedRefinements(newSelectedRefinements);
	};

	const onRefinementRemove = (
		refinementAttributeId: string,
		refinementValue: ShopperSearchTypes.ProductSearchRefinementValue
	) => {
		const newSelectedRefinements: ISelectedRefinements = { ...selectedRefinements };

		if (refinementAttributeId === 'cgid') {
			newSelectedRefinements[refinementAttributeId] = DEFAULT_REFINEMENTS.cgid;
		} else if (refinementAttributeId === 'price') {
			delete newSelectedRefinements[refinementAttributeId];
		} else {
			newSelectedRefinements[refinementAttributeId] = newSelectedRefinements[refinementAttributeId].filter(
				(selectedRefinement) => selectedRefinement.value !== refinementValue.value
			);

			if (newSelectedRefinements[refinementAttributeId].length === 0) {
				delete newSelectedRefinements[refinementAttributeId];
			}
		}

		setSelectedRefinements(newSelectedRefinements);
	};

	const renderProductHits = () => {
		if (isProductSearchResultLoading) {
			return <Spinner className="block-centered" customSize={150} />;
		} else {
			return (
				<>
					<ProductHitsList
						productHits={productSearchResult?.hits || []}
						onProductHitClick={onProductHitClick}
						selectedApiProductId={apiProduct?.id || null}
					/>
					<Pagination
						activePage={page}
						onPageChange={setPage}
						itemsPerPage={limit}
						totalItems={productSearchResult?.total || 0}
						showViewPerPage={!!productSearchResult && productSearchResult?.total > 12}
						viewPerPageOptions={[12, 24, 48]}
						onViewPerPageChange={onViewPerPageChange}
					/>
				</>
			);
		}
	};

	return (
		<>
			<Modal.Header
				onClose={() => sdk.close()}
				title="Select Product"
				className="sticky sticky--top bg-white"
			/>
			<Modal.Content>
				{!!siteListArray.length && <Box marginBottom="spacingM">
					<Text>Site for products</Text>
					<Select
							id="siteId"
							name="siteId"
							value={siteId}
							className={css({marginBottom: '1rem'})}
							onChange={(e) => setSiteId(e.target.value)}
						>
							{siteListArray.map((availableSiteId: string) => (
								<Select.Option key={`site_option_${availableSiteId}`} value={availableSiteId}>
									{availableSiteId}
								</Select.Option>
							))}
					</Select>
				</Box>}
				{productSearchResult ? (
					<Flex gap="spacingL">
						<Flex flexDirection="column" className="w-20">
							{productSearchResult.refinements && (
								<>
									<SelectedRefinements
										selectedRefinements={selectedRefinements}
										onRefinementRemove={onRefinementRemove}
										isDisabled={isProductSearchResultLoading}
									/>
									<Refinements
										refinements={productSearchResult.refinements}
										selectedRefinements={selectedRefinements}
										onRefinementSelect={onRefinementSelect}
										isDisabled={isProductSearchResultLoading}
									/>
								</>
							)}

						</Flex>
						<Flex flexDirection="column" className="w-80" >
							<Autocomplete
								inputRef={autoCompleteRef}
								placeholder="Search by id or name"
								items={productSearchResult.searchPhraseSuggestions.suggestedPhrases || []}
								itemToString={(item) => item.phrase}
								renderItem={(item) => item.phrase}
								icon={<SearchIcon variant="muted" />}
								isLoading={isProductSearchResultLoading}
								listWidth="full"
								noMatchesMessage="No suggestions"
								usePortal={true}
								onInputValueChange={onSearchProductValueChange}
								onSelectItem={(item) => onSearchProductValueChange(item.phrase)}
							/>
							<Box marginTop="spacingM" marginBottom="spacingM">
								<SortingOptions
									sortingOptions={productSearchResult.sortingOptions}
									selectedSortingOption={selectedSortingOption}
									onSortingOptionChange={setSelectedSortingOption}
									isDisabled={isProductSearchResultLoading || !productSearchResult.hits}
								/>
							</Box>
							{productSearchResult.hits && renderProductHits()}
						</Flex>
					</Flex>

				) : (
					<Spinner className="block-centered" customSize={150} />
				)}
			</Modal.Content>
			<Modal.Controls className="dialog-btn-wrapper mb-0">
				{!isProductSearchResultLoading && (
					<Button
						variant="primary"
						size="medium"
						isDisabled={!apiProduct || isApiProductLoading}
						isLoading={isApiProductLoading}
						onClick={() => sdk.close(apiProduct)}
					>
						Select Product
					</Button>
				)}
			</Modal.Controls>
		</>
	);
};

export default ProductDialog;
