import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Heading, Form, FormControl, TextInput, Flex, Tooltip } from '@contentful/f36-components';
import { HelpCircleIcon } from '@contentful/f36-icons';
import { AppExtensionSDK } from '@contentful/app-sdk';
import { css } from 'emotion';
import { useSDK } from '@contentful/react-apps-toolkit';

export interface AppInstallationParameters {
	commerceAPI: {
		clientId: string;
		organizationId: string;
		shortCode: string;
		siteId: string;
		locale: string;
	};
	openCommerceAPI: {
		clientId: string;
		clientSecret: string;
		domain: string;
		ocapiVersion: string;
		siteId: string;
		brandCode: string;
	};
	sitesList: string;
}

const ConfigScreen = () => {
	const [parameters, setParameters] = useState<AppInstallationParameters>({
		commerceAPI: {
			clientId: '',
			organizationId: '',
			shortCode: '',
			siteId: '',
			locale: '',
		},
		openCommerceAPI: {
			clientId: '',
			clientSecret: '',
			domain: '',
			ocapiVersion: '',
			siteId: '',
			brandCode: ''
		},
		sitesList: ''
	});
	const sdk = useSDK<AppExtensionSDK>();
	const isCommerceAPIFormEmpty = useMemo<boolean>(() => {
		return Object.entries(parameters.commerceAPI).every(([key, value]) => !value);
	}, [parameters.commerceAPI]);
	const isOpenCommerceAPIFormEmpty = useMemo<boolean>(() => {
		return Object.entries(parameters.openCommerceAPI).every(([key, value]) => !value);
	}, [parameters.openCommerceAPI]);

	const onConfigure = useCallback(async () => {
		// This method will be called when a user clicks on "Install"
		// or "Save" in the configuration screen.
		// for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook

		if (isCommerceAPIFormEmpty && isOpenCommerceAPIFormEmpty) {
			sdk.notifier.error('Please fill one of the API configurations');

			return false;
		}

		if (!isCommerceAPIFormEmpty) {
			if (!isValidClientId(parameters.commerceAPI.clientId)) {
				sdk.notifier.error('Commerce API Configuration: Client Id is not valid');

				return false;
			}

			if (!isValidOrganizationId(parameters.commerceAPI.organizationId)) {
				sdk.notifier.error('Commerce API Configuration: Organization Id is not valid');

				return false;
			}

			if (!isValidShortCode(parameters.commerceAPI.shortCode)) {
				sdk.notifier.error('Commerce API Configuration: Short Code is not valid');

				return false;
			}

			if (!isValidSiteId(parameters.commerceAPI.siteId)) {
				sdk.notifier.error('Commerce API Configuration: Site Id is not valid');

				return false;
			}

			if (!isValidLocale(parameters.commerceAPI.locale)) {
				sdk.notifier.error('Commerce API Configuration: Locale is not valid');

				return false;
			}
		}

		if (!isOpenCommerceAPIFormEmpty) {
			if (!isValidClientId(parameters.openCommerceAPI.clientId)) {
				sdk.notifier.error('OCAPI Configuration: Client Id is not valid');

				return false;
			}

			if (!isValidClientSecret(parameters.openCommerceAPI.clientSecret)) {
				sdk.notifier.error('OCAPI Configuration: Client Secret is not valid');

				return false;
			}

			if (!isValidDomain(parameters.openCommerceAPI.domain)) {
				sdk.notifier.error('OCAPI Configuration: Domain is not valid');

				return false;
			}

			if (!isValidOCAPIVersion(parameters.openCommerceAPI.ocapiVersion)) {
				sdk.notifier.error('OCAPI Configuration: OCAPI Version is not valid');

				return false;
			}

			if (!isValidSiteId(parameters.openCommerceAPI.siteId)) {
				sdk.notifier.error('OCAPI Configuration: Site Id is not valid');

				return false;
			}


			if (!isValidBrandCode(parameters.openCommerceAPI.brandCode)) {
				sdk.notifier.error('Commerce API Configuration: Brand Code is not valid');

				return false;
			}
		}

		// Get current the state of EditorInterface and other entities
		// related to this app installation
		const currentState = await sdk.app.getCurrentState();

		return {
			// Parameters to be persisted as the app configuration.
			parameters,
			// In case you don't want to submit any update to app
			// locations, you can just pass the currentState as is
			targetState: currentState,
		};
	}, [parameters, sdk, isCommerceAPIFormEmpty, isOpenCommerceAPIFormEmpty]);

	useEffect(() => {
		// `onConfigure` allows to configure a callback to be
		// invoked when a user attempts to install the app or update
		// its configuration.
		sdk.app.onConfigure(() => onConfigure());
	}, [sdk, onConfigure]);

	useEffect(() => {
		(async () => {
			// Get current parameters of the app.
			// If the app is not installed yet, `parameters` will be `null`.
			const currentParameters: AppInstallationParameters | null = await sdk.app.getParameters();

			if (currentParameters) {
				setParameters(currentParameters);
			}

			// Once preparation has finished, call `setReady` to hide
			// the loading screen and present the app to a user.
			sdk.app.setReady();
		})();
	}, [sdk]);

	return (
		<Flex
			flexDirection="row"
			gap="spacingXl"
			className={css({margin: '80px auto', maxWidth: '1200px'})}
		>
			<Form>
				<Heading>
					Commerce API Configuration{' '}
					<Tooltip
						placement="top"
						id="commerce-api-tooltip"
						content="Configure this section to work with Products and Categories"
					>
						<HelpCircleIcon />
					</Tooltip>
				</Heading>
				<FormControl
					isRequired={!isCommerceAPIFormEmpty}
					isInvalid={!isCommerceAPIFormEmpty && !isValidClientId(parameters.commerceAPI.clientId)}
				>
					<FormControl.Label>Client Id</FormControl.Label>
					<TextInput
						value={parameters.commerceAPI.clientId}
						type="text"
						name="clientId"
						placeholder="3a333a33-3333-333a-3a33-a3333aa3aaa3"
						onChange={(e) =>
							setParameters({
								...parameters,
								commerceAPI: { ...parameters.commerceAPI, clientId: e.target.value },
							})
						}
					/>
					{!isCommerceAPIFormEmpty && !isValidClientId(parameters.commerceAPI.clientId) && (
						<FormControl.ValidationMessage>Client Id is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isCommerceAPIFormEmpty}
					isInvalid={!isCommerceAPIFormEmpty && !isValidOrganizationId(parameters.commerceAPI.organizationId)}
				>
					<FormControl.Label>Organization Id</FormControl.Label>
					<TextInput
						value={parameters.commerceAPI.organizationId}
						type="text"
						name="organizationId"
						placeholder="f_ecom_aaaa_333"
						onChange={(e) =>
							setParameters({
								...parameters,
								commerceAPI: { ...parameters.commerceAPI, organizationId: e.target.value },
							})
						}
					/>
					{!isCommerceAPIFormEmpty && !isValidOrganizationId(parameters.commerceAPI.organizationId) && (
						<FormControl.ValidationMessage>Organization Id is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isCommerceAPIFormEmpty}
					isInvalid={!isCommerceAPIFormEmpty && !isValidShortCode(parameters.commerceAPI.shortCode)}
				>
					<FormControl.Label>Short Code</FormControl.Label>
					<TextInput
						value={parameters.commerceAPI.shortCode}
						type="text"
						name="shortCode"
						placeholder="3aa3aaaa"
						onChange={(e) =>
							setParameters({
								...parameters,
								commerceAPI: { ...parameters.commerceAPI, shortCode: e.target.value },
							})
						}
					/>
					{!isCommerceAPIFormEmpty && !isValidShortCode(parameters.commerceAPI.shortCode) && (
						<FormControl.ValidationMessage>Short Code is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isCommerceAPIFormEmpty}
					isInvalid={!isCommerceAPIFormEmpty && !isValidSiteId(parameters.commerceAPI.siteId)}
				>
					<FormControl.Label>Site Id</FormControl.Label>
					<TextInput
						value={parameters.commerceAPI.siteId}
						type="text"
						name="siteId"
						placeholder="siteId"
						onChange={(e) =>
							setParameters({
								...parameters,
								commerceAPI: { ...parameters.commerceAPI, siteId: e.target.value },
							})
						}
					/>
					{!isCommerceAPIFormEmpty && !isValidSiteId(parameters.commerceAPI.siteId) && (
						<FormControl.ValidationMessage>Site Id is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isCommerceAPIFormEmpty}
					isInvalid={!isCommerceAPIFormEmpty && !isValidLocale(parameters.commerceAPI.locale)}
				>
					<FormControl.Label>Locale</FormControl.Label>
					<TextInput
						value={parameters.commerceAPI.locale}
						type="text"
						name="locale"
						placeholder="en"
						onChange={(e) =>
							setParameters({
								...parameters,
								commerceAPI: { ...parameters.commerceAPI, locale: e.target.value },
							})
						}
					/>
					{!isCommerceAPIFormEmpty && !isValidLocale(parameters.commerceAPI.locale) && (
						<FormControl.ValidationMessage>Locale is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
			</Form>
			<Form>
				<Heading>
					OCAPI Configuration
					<Tooltip
						placement="top"
						id="ocapi-tooltip"
						content="Configure this section to work with Customer Groups"
					>
						<HelpCircleIcon />
					</Tooltip>
				</Heading>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={!isOpenCommerceAPIFormEmpty && !isValidClientId(parameters.openCommerceAPI.clientId)}
				>
					<FormControl.Label>Client Id</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.clientId}
						type="text"
						name="clientId"
						placeholder="3a333a33-3333-333a-3a33-a3333aa3aaa3"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, clientId: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidClientId(parameters.openCommerceAPI.clientId) && (
						<FormControl.ValidationMessage>Client Id is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={
						!isOpenCommerceAPIFormEmpty && !isValidClientSecret(parameters.openCommerceAPI.clientSecret)
					}
				>
					<FormControl.Label>Client Secret</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.clientSecret}
						type="text"
						name="clientSecret"
						placeholder="AAa33aAaAaA3"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, clientSecret: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidClientSecret(parameters.openCommerceAPI.clientSecret) && (
						<FormControl.ValidationMessage>Client Secret is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={!isOpenCommerceAPIFormEmpty && !isValidDomain(parameters.openCommerceAPI.domain)}
				>
					<FormControl.Label>Domain</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.domain}
						type="text"
						name="domain"
						placeholder="aaaa-333.sandbox.aa33.dx.commercecloud.salesforce.com"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, domain: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidDomain(parameters.openCommerceAPI.domain) && (
						<FormControl.ValidationMessage>Domain is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={
						!isOpenCommerceAPIFormEmpty && !isValidOCAPIVersion(parameters.openCommerceAPI.ocapiVersion)
					}
				>
					<FormControl.Label>OCAPI Version</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.ocapiVersion}
						type="text"
						name="ocapiVersion"
						placeholder="v33_33"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, ocapiVersion: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidOCAPIVersion(parameters.openCommerceAPI.ocapiVersion) && (
						<FormControl.ValidationMessage>OCAPI Version is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={!isOpenCommerceAPIFormEmpty && !isValidSiteId(parameters.openCommerceAPI.siteId)}
				>
					<FormControl.Label>Site Id</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.siteId}
						type="text"
						name="siteId"
						placeholder="siteId"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, siteId: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidSiteId(parameters.openCommerceAPI.siteId) && (
						<FormControl.ValidationMessage>Site Id is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
				<FormControl
					isRequired={!isOpenCommerceAPIFormEmpty}
					isInvalid={!isOpenCommerceAPIFormEmpty && !isValidBrandCode(parameters.openCommerceAPI.brandCode)}
				>
					<FormControl.Label>Brand Code</FormControl.Label>
					<TextInput
						value={parameters.openCommerceAPI.brandCode}
						type="text"
						name="brandCode"
						placeholder="brandCode"
						onChange={(e) =>
							setParameters({
								...parameters,
								openCommerceAPI: { ...parameters.openCommerceAPI, brandCode: e.target.value },
							})
						}
					/>
					{!isOpenCommerceAPIFormEmpty && !isValidBrandCode(parameters.openCommerceAPI.brandCode) && (
						<FormControl.ValidationMessage>Brand code is not set</FormControl.ValidationMessage>
					)}
				</FormControl>
			</Form>
			<Form
			 className={css({maxWidth: '33%'})}>
				<Heading>
					Sites Configuration
					<Tooltip
						placement="top"
						id="ocapi-tooltip"
						content="Configure this section to work with Multisites"
					>
						<HelpCircleIcon />
					</Tooltip>
				</Heading>
				<FormControl
					isRequired={false}
				>
					<FormControl.Label>Sites Ids (format separated by coma(',') 'site1, site2')</FormControl.Label>
					<TextInput
						value={parameters.sitesList}
						type="text"
						name="sitesList"
						placeholder="sitesList"
						onChange={(e) =>
							setParameters({
								...parameters,
								sitesList: e.target.value,
							})
						}
					/>
				</FormControl>
			</Form>
		</Flex>
	);
};

/**
 * Checks if locale is valid
 * @param {String} locale - locale
 * @returns {Boolean} - is valid locale
 */
function isValidLocale(locale: string): boolean {
	return !!locale;
}

/**
 * Checks if client id is valid
 * @param {String} clientId - client id to check
 * @returns {Boolean} - is valid client id
 */
function isValidClientId(clientId: string): boolean {
	return !!clientId;
}

/**
 * Checks if client secret is valid
 * @param {String} clientSecret - client secret to check
 * @returns {Boolean} - is valid client secret
 */
function isValidClientSecret(clientSecret: string): boolean {
	return !!clientSecret;
}

/**
 * Checks if organization id is valid
 * @param {String} organizationId - organization id to check
 * @returns {Boolean} - is valid organization id
 */
function isValidOrganizationId(organizationId: string): boolean {
	return !!organizationId;
}

/**
 * Checks if short code is valid
 * @param {String} shortCode - short code to check
 * @returns {Boolean} - is valid short code
 */
function isValidShortCode(shortCode: string): boolean {
	return !!shortCode;
}

/**
 * Checks if site id is valid
 * @param {String} siteId - site id to check
 * @returns {Boolean} - is valid site id
 */
function isValidSiteId(siteId: string): boolean {
	return !!siteId;
}

/**
 * Checks if domain is valid
 * @param {String} domain - domain to check
 * @returns {Boolean} - is valid site id
 */
function isValidDomain(domain: string): boolean {
	return !!domain;
}

/**
 * Checks if ocapi version is valid
 * @param {String} ocapiVersion - ocapi version to check
 * @returns {Boolean} - is valid ocapi version
 */
function isValidOCAPIVersion(ocapiVersion: string): boolean {
	return !!ocapiVersion;
}

/**
 * Checks if brand code is valid
 * @param {String} brand - brand code
 * @returns {Boolean} - is valid brand code
 */
function isValidBrandCode(brandCode: string): boolean {
	return !!brandCode;
}

export default ConfigScreen;
