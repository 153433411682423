// @ts-nocheck

import React, { useMemo } from 'react';
import { Paragraph } from '@contentful/f36-components';
import { CategoryDialog, CustomerGroupDialog, ProductDialog } from '../components/Dialogs';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import CommerceAPI from '../classes/api/commerce';
import OpenCommerceAPI from '../classes/api/ocapi';

const OCAPI_OBJECT_TYPES = ['customerGroup', 'category'];

const Dialog = () => {
	const sdk = useSDK<DialogExtensionSDK>();
	const isOCAPIClientType = useMemo<boolean>(() => {
		return OCAPI_OBJECT_TYPES.indexOf(sdk.parameters.invocation.objectType) !== -1;
	}, [sdk]);
	const apiClient = useMemo<OpenCommerceAPI | CommerceAPI>(() => {
		if (isOCAPIClientType) {
			return new OpenCommerceAPI({
				proxy: window.location.origin,
				parameters: {
					clientId: sdk.parameters.installation.openCommerceAPI.clientId,
					clientSecret: sdk.parameters.installation.openCommerceAPI.clientSecret,
					domain: sdk.parameters.installation.openCommerceAPI.domain,
					ocapiVersion: sdk.parameters.installation.openCommerceAPI.ocapiVersion,
					siteId: sdk.parameters.installation.openCommerceAPI.siteId,
					brandCode: sdk.parameters.installation.openCommerceAPI.brandCode,
				},
			});
		} else {
			return new CommerceAPI({
				proxy: window.location.origin,
				headers: {
					'x-short-code': sdk.parameters.installation.commerceAPI.shortCode,
				},
				parameters: {
					clientId: sdk.parameters.installation.commerceAPI.clientId,
					organizationId: sdk.parameters.installation.commerceAPI.organizationId,
					shortCode: sdk.parameters.installation.commerceAPI.shortCode,
					siteId: sdk.parameters.installation.commerceAPI.siteId,
					locale: sdk.parameters.installation.commerceAPI.locale || sdk.parameters.invocation.locale,
					channelId: sdk.parameters.installation.commerceAPI.siteId,
				},
				throwOnBadResponse: true,
			});
		}
	}, [sdk, isOCAPIClientType]);
	const isApiClientValid = useMemo(() => {
		return apiClient.isValid();
	}, [apiClient]);

	if (!isApiClientValid) {
		return (
			<Paragraph>
				To use Object Type {sdk.parameters.invocation.objectType}, please update App Configuration and fill the
				section {isOCAPIClientType ? 'OCAPI' : 'Commerce API'} Configuration
			</Paragraph>
		);
	}

	switch (sdk.parameters.invocation.objectType) {
		case 'category':
			return <CategoryDialog apiClient={apiClient} />;
		case 'product':
			return <ProductDialog apiClient={apiClient} />;
		case 'customerGroup':
			return <CustomerGroupDialog apiClient={apiClient} />;
		default:
			return (
				<Paragraph>
					Integration is not done for selected Object Type: {sdk.parameters.invocation.objectType}
				</Paragraph>
			);
	}
};

export default Dialog;
