import React from 'react';
import { Stack } from '@contentful/f36-components';
import { SelectedRefinementValue } from './Subcomponents';

import { ISelectedRefinementsProps } from './ISelectedRefinements';

const SelectedRefinements = ({ selectedRefinements, onRefinementRemove, isDisabled }: ISelectedRefinementsProps) => {
	return (
		<Stack className="list-scrollable--axis-x">
			{Object.entries(selectedRefinements).map(([key, value]) => {
				if (key === 'cgid' && value[0].value === 'root') {
					return null;
				}

				return (
					<React.Fragment key={key}>
						{value.map((selectedRefinementValue) => {
							return (
								<SelectedRefinementValue
									key={key + selectedRefinementValue.value}
									refinementValue={selectedRefinementValue}
									onRefinementValueRemove={(refinementValue) =>
										onRefinementRemove(key, refinementValue)
									}
									isDisabled={isDisabled}
								/>
							);
						})}
					</React.Fragment>
				);
			})}
		</Stack>
	);
};

export default SelectedRefinements;
