import React from 'react';
import { Accordion, SectionHeading } from '@contentful/f36-components';
import { Refinement } from './Subcomponents';

import { IRefinementsProps } from './IRefinements';

const Refinements = ({ refinements, selectedRefinements, onRefinementSelect, isDisabled }: IRefinementsProps) => {
	return (
		<Accordion className="list-unstyled list-scrollable refinement-accordion">
			{refinements.map((refinement) => {
				return refinement?.values ? (
					<Accordion.Item
						className="accordion-item"
						key={refinement.attributeId}
						title={
							<SectionHeading isTruncated marginBottom="none">
								{refinement.label}
 							</SectionHeading>
						}
					>
						<Refinement
							key={refinement.attributeId}
							refinement={refinement}
							selectedRefinementValues={selectedRefinements[refinement.attributeId] || []}
							onRefinementSelect={onRefinementSelect}
							isDisabled={isDisabled}
						/>
					</Accordion.Item>
				) : null;
			})}
		</Accordion>
	);
};

export default Refinements;
