import React, { useEffect, useState, useMemo } from 'react';
import { Button, Modal, TextInput, Spinner, Switch, Flex, Box, Text, Select } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';
import { CategoriesTree, CategoriesList } from './Subcomponents';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { css } from 'emotion';

import type { ShopperProductsTypes } from 'commerce-sdk-isomorphic';
import { ICategoryDialogProps } from './ICategoryDialog';
import { ICategory } from '../../../classes/api/ocapi/IOpenCommerceAPI';

const CategoryDialog = ({ apiClient }: ICategoryDialogProps) => {
	const sdk = useSDK<DialogExtensionSDK>();
	const [apiCategory, setApiCategory] = useState<ICategory | null>(null);
	const [apiCategoriesTree, setApiCategoriesTree] = useState<ICategory | null>(null);
	const [isApiCategoriesTreeLoading, setIsApiCategoriesTreeLoading] = useState<boolean>(false);
	const [isFlattenCategoriesTree, setIsFlattenCategoriesTree] = useState<boolean>(false);
	const [searchCategoryValue, setSearchCategoryValue] = useState<string>('');
	const flattenedApiCategories = useMemo<Array<ICategory>>(() => {
		const flattenedApiCategories: Array<ICategory> = apiCategoriesTree
			? flattenCategoriesTree(apiCategoriesTree)
			: [];

		return flattenedApiCategories.filter((category) => {
			return category.id !== 'root';
		});
	}, [apiCategoriesTree]);
	const [siteId, setSiteId] = useState<string>(sdk.parameters.installation?.openCommerceAPI?.siteId);
	const siteListArray = sdk.parameters.installation?.sitesList.split(',').map((site: string) => site.trim()) || [];

	useEffect(() => {
		(async () => {
			setIsApiCategoriesTreeLoading(true);
			apiClient.setSiteId(siteId);

			let category: ICategory | null = null;

			try {
				category = await apiClient.getCategory('root', 4);
			} catch (e: any) {
				sdk.notifier.error(`Failed to get categories from SFCC due to the error: ${e.message}`);
			}

			setApiCategoriesTree(category);
			setIsApiCategoriesTreeLoading(false);
		})();
	}, [sdk.notifier, apiClient, siteId]);

	const renderCategories = () => {
		if (searchCategoryValue) {
			const searchCategoryValueRegExp: RegExp = new RegExp(searchCategoryValue, 'i');
			const matchedCategories: Array<ICategory> = flattenedApiCategories.filter(
				(category) => {
					return category.id?.match(searchCategoryValueRegExp) ||
					category.name?.default?.match(searchCategoryValueRegExp);
				}
			);

			if (!matchedCategories.length) {
				return <Text>No results found</Text>
			}

			return <CategoriesList selectedApiCategoryId={apiCategory?.id || null} categories={matchedCategories} onCategoryClick={setApiCategory} />;
		} else {
			if (isFlattenCategoriesTree) {
				return <CategoriesList selectedApiCategoryId={apiCategory?.id || null} categories={flattenedApiCategories} onCategoryClick={setApiCategory} />;
			} else {
				return <CategoriesTree selectedApiCategoryId={apiCategory?.id || null} rootCategory={apiCategoriesTree} onCategoryClick={setApiCategory} />;
			}
		}
	};

	return (
		<>
			<Modal.Header onClose={() => sdk.close()} title="Select Category" />
			<Modal.Content className="category-content-modal">
				{!!siteListArray.length && <Box marginBottom="spacingM">
					<Text>Site for customer groups</Text>
					<Select
							id="siteId"
							name="siteId"
							value={siteId}
							className={css({marginBottom: '1rem'})}
							onChange={(e) => setSiteId(e.target.value)}
						>
							{siteListArray.map((availableSiteId: string) => (
								<Select.Option key={`site_option_${availableSiteId}`} value={availableSiteId}>
									{availableSiteId}
								</Select.Option>
							))}
					</Select>
				</Box>}
				<TextInput
					aria-label="Search by name"
					placeholder="Search by name"
					id="search-category"
					value={searchCategoryValue}
					onChange={(e) => {
						setApiCategory(null);
						setSearchCategoryValue(e.target.value);
					}}
					icon={<SearchIcon />}
					isDisabled={isApiCategoriesTreeLoading || !apiCategoriesTree}
				/>
				<Flex marginBottom="spacingM" marginTop="spacingM" justifyContent="flex-end">
					<Switch
						name="flatten-categories-tree"
						id="flatten-categories-tree"
						isChecked={isFlattenCategoriesTree}
						isDisabled={isApiCategoriesTreeLoading || !apiCategoriesTree || !!searchCategoryValue}
						onChange={() => {
							setApiCategory(null);
							setIsFlattenCategoriesTree(!isFlattenCategoriesTree);
						}}
					>
						Flattened view
					</Switch>
				</Flex>
				{isApiCategoriesTreeLoading ? <Spinner className="block-centered" customSize={150} /> : apiCategoriesTree && renderCategories()}
			</Modal.Content>
			{!isApiCategoriesTreeLoading && (
				<Modal.Controls className="dialog-btn-wrapper mb-0">
					<Button
						variant="primary"
						size="medium"
						isDisabled={!apiCategory}
						onClick={() => sdk.close(apiCategory)}>
							Select Category
					</Button>
				</Modal.Controls>
			)}
		</>
	);
};

/**
 * Flattens categories tree into categories array
 * @param {ICategory} categoriesTree - categories tree
 * @returns {Array<ICategory>} - categories array
 */
function flattenCategoriesTree(categoriesTree: ICategory): Array<ICategory> {
	const subCategories: Array<Array<ICategory>> = (categoriesTree.categories || []).map(
		(subCategory) => flattenCategoriesTree(subCategory)
	);

	return [categoriesTree, ...subCategories.flat()];
}

export default CategoryDialog;
