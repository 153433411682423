// @ts-nocheck

import { helpers, ShopperLogin, ShopperSearch, ShopperProducts, ShopperProductsTypes } from 'commerce-sdk-isomorphic';
import { ISelectedRefinements } from '../../../components/Dialogs/ProductDialog/IProductDialog';
import OAuth2Token from '../classes/OAuth2Token';

import { ICommerceAPI } from './ICommerceAPI';

/**
 * Represents CommerceAPI
 */
class CommerceAPI implements ICommerceAPI {
	private config: any;
	private oauth2Token: OAuth2Token | null;

	/**
	 * @constructor
	 * @param {Object} config - config
	 */
	constructor(config: any) {
		this.config = config;
		this.oauth2Token = null;
	}

	/**
	 * Set Site id to be used
	 * @param {String} siteId - site id
	 */
	setSiteId(siteId) {
		if (siteId) {
			this.config.parameters.siteId = siteId;
		}
	}

	/**
	 * Retrieves site id
	 * @returns {String} - site id
	 */
	getSiteId() {
		return this.config?.parameters?.siteId;
	}

	/**
	 * Checks if client is correctly configured
	 * @returns {Boolean} - is valid
	 */
	isValid(): boolean {
		if (!this.config?.parameters?.clientId) {
			return false;
		}

		if (!this.config?.parameters?.organizationId) {
			return false;
		}

		if (!this.config?.parameters?.shortCode) {
			return false;
		}

		if (!this.config?.parameters?.siteId) {
			return false;
		}

		return true;
	}

	/**
	 * Retrieves access token
	 * @returns {String} - access token
	 */
	async getAccessToken(): Promise<string> {
		if (this.oauth2Token) {
			if (this.oauth2Token.isValid()) {
				return this.oauth2Token.getAccessToken();
			} else if (this.oauth2Token.getRefreshToken()) {
				const shopperLogin = new ShopperLogin(this.config);
				const { access_token, token_type, refresh_token, expires_in } = await helpers.refreshAccessToken(
					shopperLogin,
					{
						refreshToken: this.oauth2Token.getRefreshToken(),
					}
				);

				if (access_token) {
					this.oauth2Token = new OAuth2Token({
						accessToken: access_token,
						tokenType: token_type,
						refreshToken: refresh_token,
						expiresIn: expires_in,
					});

					return this.oauth2Token.getAccessToken();
				}
			}
		}

		const shopperLogin = new ShopperLogin(this.config);
		const { access_token, token_type, refresh_token, expires_in } = await helpers.loginGuestUser(shopperLogin, {
			redirectURI: `${this.config.proxy}/callback`,
		});

		if (access_token) {
			this.oauth2Token = new OAuth2Token({
				accessToken: access_token,
				tokenType: token_type,
				refreshToken: refresh_token,
				expiresIn: expires_in,
			});

			return this.oauth2Token.getAccessToken();
		}

		throw new Error(`Failed to retrieve token`);
	}

	/**
	 * Retrieves category
	 * @param {String} categoryId - category id
	 * @param {Number} levels - depth of subcategories to retrieve
	 * @returns {ShopperProductsTypes.Category} - category
	 */
	async getCategory(categoryId: string = 'root', levels: number = 4): Promise<ShopperProductsTypes.Category> {
		const accessToken: string = await this.getAccessToken();
		const shopperProducts = new ShopperProducts({
			...this.config,
			headers: { ...this.config.headers, authorization: `Bearer ${accessToken}` },
		});
		const category: ShopperProductsTypes.Category = await shopperProducts.getCategory({
			parameters: {
				id: categoryId,
				levels: levels,
			},
		});

		return category;
	}

	/**
	 * Searches products
	 * @param {String} query - query string to search by
	 * @param {Object} refinements - refinements to apply
	 * @param {String} sortingOption - sorting to apply
	 * @param {Number} limit - limit of products to return
	 * @param {Number} offset - offset position to start fetching from. E.g. give me products starting from 10th product till limit
	 * @returns {Promise<ShopperSearchTypes.ProductSearchResult>} - product search result
	 */
	async searchProducts(
		query: string = '',
		refinements: ISelectedRefinements = {},
		sortingOption: string = '',
		limit: number = 24,
		offset: number = 0
	) {
		const accessToken: string = await this.getAccessToken();
		const shopperSearch = new ShopperSearch({
			...this.config,
			headers: { ...this.config.headers, authorization: `Bearer ${accessToken}` },
		});
		const productSearchResult = await shopperSearch.productSearch({
			parameters: {
				q: query,
				refine: Object.keys(refinements).map(
					(key) =>
						`${key}=${refinements[key]
							.map((refinementValue) => {
								return refinementValue.value;
							})
							.join('|')}`
				),
				sort: sortingOption,
				limit: limit,
				offset: offset,
			},
		});

		return productSearchResult;
	}

	/**
	 * Retrieves product
	 * @param {String} productId - product id
	 * @returns {Promise<ShopperProductsTypes.Product>} - product
	 */
	async getProduct(productId: string): Promise<ShopperProductsTypes.Product> {
		const accessToken: string = await this.getAccessToken();
		const shopperProducts = new ShopperProducts({
			...this.config,
			headers: { ...this.config.headers, authorization: `Bearer ${accessToken}` },
		});
		const product: ShopperProductsTypes.Product = await shopperProducts.getProduct({
			parameters: {
				id: productId,
			},
		});

		return product;
	}
}

export default CommerceAPI;
