import React from 'react';
import { List, Accordion, SectionHeading } from '@contentful/f36-components';
import CategoriesTree from '../../CategoriesTree';
import { Category } from './Subcomponents';

import { ICategoryTreeItemProps } from './ICategoryTreeItem';

const CategoryTreeItem = ({ category, onCategoryClick, selectedApiCategoryId }: ICategoryTreeItemProps) => {
	if (category.categories) {
		return (
			<List.Item key={category.id}>
				<Accordion>
					<Accordion.Item
						key={category.id}
						title={
							<SectionHeading className="w-100 text-transform-none" marginBottom="none">
								<Category selectedApiCategoryId={selectedApiCategoryId} category={category} onCategoryClick={onCategoryClick} />
							</SectionHeading>
						}
					>
						<CategoriesTree selectedApiCategoryId={selectedApiCategoryId} rootCategory={category} onCategoryClick={onCategoryClick} />
					</Accordion.Item>
				</Accordion>
			</List.Item>
		);
	} else {
		return (
			<List.Item key={category.id}>
				<Category isBordered={true} selectedApiCategoryId={selectedApiCategoryId} category={category} onCategoryClick={onCategoryClick} />
			</List.Item>
		);
	}
};

export default CategoryTreeItem;
