// @ts-nocheck

import React from 'react';
import { EntryCard, MenuItem } from '@contentful/f36-components';

import { IProductProps } from './IProduct';

const Product = ({ product, onProductSelect, onProductRemove, withDragHandle, dragHandle }: IProductProps) => {
	const renderThumbnailElement = () => {
		if (!product.imageGroups?.length || !product.imageGroups[0].images?.length) {
			return null as any;
		}

		const thumbnailImage = product.imageGroups[0].images[0];

		return <img alt={thumbnailImage.alt} title={thumbnailImage.title} src={thumbnailImage.link} />;
	};

	return (
		<EntryCard
			contentType={product.id}
			title={product.name}
			onClick={() => onProductSelect(product)}
			actions={[
				<MenuItem key="remove" onClick={() => onProductRemove(product)}>
					Remove
				</MenuItem>,
			]}
			thumbnailElement={renderThumbnailElement()}
			withDragHandle={withDragHandle}
			padding={withDragHandle ? 'default' : 'none'}
			dragHandleRender={() => (withDragHandle ? dragHandle : undefined)}
		/>
	);
};

export default Product;
