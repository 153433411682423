import React from 'react';
import { List, TextLink } from '@contentful/f36-components';

import { IRefinementValueProps } from './IRefinementValue';

const RefinementValue = ({
	refinementValue,
	selectedRefinementValues,
	onRefinementValueClick,
	isDisabled,
}: IRefinementValueProps) => {
	const isSelected = selectedRefinementValues.some(
		(selectedRefinementValue) => selectedRefinementValue.value === refinementValue.value
	);

	return (
		<List.Item key={refinementValue.value}>
			<TextLink
				as="button"
				variant={isSelected ? 'primary' : 'secondary'}
				isDisabled={isDisabled}
				onClick={() => {
					if (!isSelected) {
						onRefinementValueClick(refinementValue);
					}
				}}
			>
				{refinementValue.label} ({refinementValue.hitCount})
			</TextLink>
			{refinementValue.values && (
				<List>
					{refinementValue.values.map((subRefinementValue) => {
						return (
							<RefinementValue
								key={subRefinementValue.value}
								refinementValue={subRefinementValue}
								selectedRefinementValues={selectedRefinementValues}
								onRefinementValueClick={onRefinementValueClick}
								isDisabled={isDisabled}
							/>
						);
					})}
				</List>
			)}
		</List.Item>
	);
};

export default RefinementValue;
