import React from 'react';
import { Pill, Box } from '@contentful/f36-components';

import { ISelectedRefinementValueProps } from './ISelectedRefinementValue';

const SelectedRefinementValue = ({
	refinementValue,
	onRefinementValueRemove,
	isDisabled,
}: ISelectedRefinementValueProps) => {
	return (
		<Box marginBottom="spacingXs">
			<Pill
				key={refinementValue.value}
				label={refinementValue.label}
				variant={isDisabled ? 'active' : 'idle'}
				onClose={
					isDisabled
						? undefined
						: () => {
								onRefinementValueRemove(refinementValue);
						}
				}
			/>
		</Box>
	);
};

export default SelectedRefinementValue;
