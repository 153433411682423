import React from 'react';
import { EntityList } from '@contentful/f36-components';

import { IProductHitProps } from './IProductHit';

const ProductHit = ({ productHit, onProductHitClick, selectedApiProductId }: IProductHitProps) => {
	const getListItemCssClasses = () => {
		let cssClasses = 'clickable-list-item';

		if (selectedApiProductId === productHit.productId) {
			cssClasses += ' list-item-active';
		}

		return cssClasses;
	}

	return (
		<EntityList.Item
			key={productHit.productId}
			title={productHit.productName || ''}
			description={`Product type: ${productHit.hitType}`}
			contentType={productHit.productId}
			thumbnailUrl={productHit.image?.link}
			onClick={() => onProductHitClick(productHit)}
			className={getListItemCssClasses()}
		/>
	);
};

export default ProductHit;
