// @ts-nocheck

import React from 'react';
import { EntryCard, MenuItem } from '@contentful/f36-components';

import { ICustomerGroupProps } from './ICustomerGroup';

const CustomerGroup = ({
	customerGroup,
	onCustomerGroupSelect,
	onCustomerGroupRemove,
	withDragHandle,
	dragHandle,
}: ICustomerGroupProps) => {
	return (
		<EntryCard
			contentType={customerGroup.id}
			title={customerGroup.id}
			onClick={() => onCustomerGroupSelect(customerGroup)}
			actions={[
				<MenuItem key="remove" onClick={() => onCustomerGroupRemove(customerGroup)}>
					Remove
				</MenuItem>,
			]}
			withDragHandle={withDragHandle}
			padding={withDragHandle ? 'default' : 'none'}
			dragHandleRender={() => (withDragHandle ? dragHandle : undefined)}
		/>
	);
};

export default CustomerGroup;
