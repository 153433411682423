import React from 'react';
import { EntityList } from '@contentful/f36-components';
import { Category } from './Subcomponents';

import { ICategoriesListProps } from './ICategoriesList';

const CategoriesList = ({ categories, onCategoryClick, selectedApiCategoryId }: ICategoriesListProps) => {
	return (
		<EntityList>
			{categories.map((category) => {
				return <Category selectedApiCategoryId={selectedApiCategoryId} key={category.id} category={category} onCategoryClick={onCategoryClick} />;
			})}
		</EntityList>
	);
};

export default CategoriesList;
