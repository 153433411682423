import React from 'react';
import { Select } from '@contentful/f36-components';
import { SortingOption } from './Subcomponents';

import { ISortingOptionsProps } from './ISortingOptions';

const SortingOptions = ({
	sortingOptions,
	selectedSortingOption,
	onSortingOptionChange,
	isDisabled,
}: ISortingOptionsProps) => {
	return (
		<Select
			name="sorting-option-select"
			id="sorting-option-select"
			value={selectedSortingOption}
			isDisabled={isDisabled}
			onChange={(e) => onSortingOptionChange(e.target.value)}
		>
			<Select.Option key="default" value="">
				Select sorting order...
			</Select.Option>
			{sortingOptions.map((sortingOption) => {
				return <SortingOption key={sortingOption.id} sortingOption={sortingOption} />;
			})}
		</Select>
	);
};

export default SortingOptions;
