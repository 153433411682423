import React from 'react';
import { EntityList, Box } from '@contentful/f36-components';

import { IProductsHitsListProps } from './IProductHitsList';
import { ProductHit } from './Subcomponents';

const ProductHitsList = ({ productHits, onProductHitClick, selectedApiProductId }: IProductsHitsListProps) => {
	return (
		<Box marginBottom="spacingM">
			<EntityList className="list-scrollable list-scrollable--product">
				{productHits.map((productHit) => {
					return (
						<ProductHit
							key={productHit.productId}
							productHit={productHit}
							onProductHitClick={onProductHitClick}
							selectedApiProductId={selectedApiProductId}
						/>
					);
				})}
			</EntityList>
		</Box>
	);
};

export default ProductHitsList;
