// @ts-nocheck

import React, { useState } from 'react';
import { Paragraph, Flex, Button, DragHandle, Box } from '@contentful/f36-components';
import { Category, CustomerGroup, Product } from '../components/Field';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { useSDK, useAutoResizer } from '@contentful/react-apps-toolkit';
import { arrayMoveImmutable } from 'array-move';
import { css } from 'emotion';
import _ from 'lodash';

const SUPPORTED_OBJECT_TYPES = {
	category: {
		label: 'Category',
	},
	product: {
		label: 'Product',
	},
	customerGroup: {
		label: 'Customer Group',
	},
};

const Field = () => {
	const sdk = useSDK<FieldExtensionSDK>();

	const getInitFieldValue = () => {
		const fieldType = sdk.field.type;

		switch (fieldType) {
			case 'Object':

				return sdk.field.getValue()?.items || [];
			case 'Symbol':
				if (sdk.field.getValue()) {
					return [{'id': sdk.field.getValue()}];
				}

				return [];
		}
	};

	const [apiItems, setApiItems] = useState<Array<any>>(getInitFieldValue());

	useAutoResizer();

	const setFieldValue = (apiItems, isOverriding = false) => {
		const fieldType = sdk.field.type;

		switch (fieldType) {
			case 'Object':
				if (!isOverriding) {
					sdk.field.setValue(
						{ type: sdk.parameters.instance.objectType, items: [...apiItems] },
						sdk.field.locale
					);
				} else {
					sdk.field.setValue({ type: sdk.parameters.instance.objectType, items: apiItems });
				}

				break;
			case 'Symbol':
				if (!isOverriding && apiItems && apiItems.length === 1) {
					const firstItem = apiItems[0];
					const keys = Object.keys(firstItem);

					if (keys && keys.length === 1) {
						sdk.field.setValue(firstItem[keys[0]]);
					}
				} else if (isOverriding && apiItems && apiItems.length === 0 ) {
					sdk.field.setValue('');
				}

				break;
		}
	};

	const openDialog = () => {
		return sdk.dialogs
			.openCurrent({
				width: 'fullWidth',
				minHeight: 580,
				shouldCloseOnOverlayClick: true,
				shouldCloseOnEscapePress: true,
				parameters: {
					objectType: sdk.parameters.instance.objectType,
					locale: sdk.field.locale,
				},
			})
			.then((data) => {
				if (data) {
					const objectAttributes: string =
						sdk.parameters.instance.objectAttributes && sdk.parameters.instance.objectAttributes.trim();
					let newApiItem: any = {};

					if (objectAttributes) {
						newApiItem = _.reduce(
							['id', ...objectAttributes.split(',')],
							(o: any, p: any) => (_.get(data, p) ? _.set(o, p, _.get(data, p)) : o),
							{}
						);
					} else {
						newApiItem = data;
					}

					return newApiItem;
				}
			});
	};

	const addApiItem = () => {
		openDialog().then((newApiItem) => {
			if (newApiItem) {
				if (apiItems.some((apiItem) => apiItem.id === newApiItem.id)) {
					sdk.notifier.error(
						`${SUPPORTED_OBJECT_TYPES[sdk.parameters.instance.objectType].label} was not added. ${
							SUPPORTED_OBJECT_TYPES[sdk.parameters.instance.objectType].label
						} with same id already added`
					);
				} else {
					if (Array.isArray(newApiItem)) {
						setFieldValue(newApiItem);
						setApiItems(newApiItem);
					} else {
						apiItems.push(newApiItem);

						setFieldValue(apiItems);

						setApiItems([...apiItems]);
					}
				}
			}
		});
	};

	const editApiItem = (oldApiItem: any) => {
		openDialog().then((newApiItem) => {
			if (newApiItem) {
				if (Array.isArray(newApiItem)) {
					setFieldValue(newApiItem);
					setApiItems(newApiItem);
				} else {
					apiItems[apiItems.indexOf(oldApiItem)] = newApiItem;

					setFieldValue(apiItems);

					setApiItems([...apiItems]);
				}

			}
		});
	};

	const removeApiItem = (apiItem: any) => {
		const filteredApiItems = apiItems.filter((item) => item !== apiItem);

		setFieldValue(filteredApiItems, true);
		setApiItems(filteredApiItems);
	};

	const swapItems = ({ oldIndex, newIndex }: any) => {
		const newApiItems = arrayMoveImmutable(apiItems, oldIndex, newIndex);

		setFieldValue(newApiItems, true);
		setApiItems(newApiItems);
	};

	const styles = {
		dragHandle: css({
			alignSelf: 'stretch',
		}),
	};
	const SortableDragHandle = SortableHandle(() => (
		<DragHandle as="button" className={styles.dragHandle} label="Move card" />
	));

	const SortableList = SortableContainer((props: any) => <Flex flexDirection="column">{props.children}</Flex>);

	const SortableCard = SortableElement(({ apiItem }) => {
		switch (sdk.parameters.instance.objectType) {
			case 'category':
				return (
					<Category
						category={apiItem}
						onCategorySelect={editApiItem}
						onCategoryRemove={removeApiItem}
						withDragHandle={apiItems.length > 1}
						dragHandle={<SortableDragHandle />}
					/>
				);
			case 'product':
				return (
					<Product
						product={apiItem}
						onProductSelect={editApiItem}
						onProductRemove={removeApiItem}
						withDragHandle={apiItems.length > 1}
						dragHandle={<SortableDragHandle />}
					/>
				);
			case 'customerGroup':
				return (
					<CustomerGroup
						customerGroup={apiItem}
						onCustomerGroupSelect={editApiItem}
						onCustomerGroupRemove={removeApiItem}
						withDragHandle={apiItems.length > 1}
						dragHandle={<SortableDragHandle />}
					/>
				);
		}
	});

	if (!SUPPORTED_OBJECT_TYPES[sdk.parameters.instance.objectType]) {
		return (
			<Paragraph>
				Integration is not done for selected Object Type: {sdk.parameters.instance.objectType}
			</Paragraph>
		);
	}

	return (
		<>
			<SortableList
				useDragHandle={apiItems.length > 1}
				axis="y"
				distance={10}
				onSortEnd={({ oldIndex, newIndex }) => {
					swapItems({ oldIndex, newIndex });
				}}
			>
				{apiItems.map((apiItem, index) => {
					return (
						<SortableCard
							key={apiItem.id + index}
							index={index}
							apiItem={apiItem}
							disabled={apiItems.length < 2}
						/>
					);
				})}
			</SortableList>
			{sdk.parameters.instance.isManyReferences ? (
				<Box marginTop="spacingM">
					<Button onClick={addApiItem}>
						Add {SUPPORTED_OBJECT_TYPES[sdk.parameters.instance.objectType].label}
					</Button>
				</Box>
			) : (
				apiItems.length === 0 && (
					<Box marginTop="spacingM">
						<Button onClick={addApiItem}>
							Select {SUPPORTED_OBJECT_TYPES[sdk.parameters.instance.objectType].label}
						</Button>
					</Box>
				)
			)}
		</>
	);
};

export default Field;
