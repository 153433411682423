import React from 'react';
import { List } from '@contentful/f36-components';
import { CategoryTreeItem } from './Subcomponents';

import { ICategoriesTreeProps } from './ICategoriesTree';

const CategoriesTree = ({ rootCategory, onCategoryClick, selectedApiCategoryId }: ICategoriesTreeProps) => {
	if (!rootCategory) {
		return null;
	}

	return (
		<List className="list-unstyled">
			{rootCategory.categories?.map((subCategory) => {
				return (
					<CategoryTreeItem selectedApiCategoryId={selectedApiCategoryId} key={subCategory.id} category={subCategory} onCategoryClick={onCategoryClick} />
				);
			})}
		</List>
	);
};

export default CategoriesTree;
