import React from 'react';
import { Select } from '@contentful/f36-components';

import { ISortingOptionProps } from './ISortingOption';

const SortingOption = ({ sortingOption }: ISortingOptionProps) => {
	return (
		<Select.Option key={sortingOption.id} value={sortingOption.id}>
			{sortingOption.label}
		</Select.Option>
	);
};

export default SortingOption;
