import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Pagination, TextInput, Box, Text, Select } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';
import { CustomerGroupsList } from './Subcomponents';
import { DialogExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { css } from 'emotion';

import { ICustomerGroupDialogProps } from './ICustomerGroupDialog';
import { ICustomerGroup, ICustomerGroupResult } from '../../../classes/api/ocapi/IOpenCommerceAPI';

let debounce: NodeJS.Timeout | undefined;

const CustomerGroupDialog = ({ apiClient }: ICustomerGroupDialogProps) => {
	const sdk = useSDK<DialogExtensionSDK>();
	const [apiCustomerGroup, setApiCustomerGroup] = useState<ICustomerGroup[]>([]);
	const [customerGroupsResult, setCustomerGroupsResult] = useState<ICustomerGroupResult | null>(null);
	const [isCustomerGroupsResultLoading, setIsCustomerGroupsResultLoading] = useState<boolean>(false);
	const [searchCustomerGroupValue, setSearchCustomerGroupValue] = useState<string>('');
	const [query, setQuery] = useState<string>('');
	const [page, setPage] = useState<number>(0);
	const [limit, setLimit] = useState<number>(12);
	const [siteId, setSiteId] = useState<string>(sdk.parameters.installation?.openCommerceAPI?.siteId);
	const siteListArray = sdk.parameters.installation?.sitesList.split(',').map((site: string) => site.trim()) || [];

	useEffect(() => {
		(async () => {
			setIsCustomerGroupsResultLoading(true);
			apiClient.setSiteId(siteId);

			let customerGroupsResult: ICustomerGroupResult | null = null;

			if (query) {
				try {
					customerGroupsResult = await apiClient.searchCustomerGroups(query, limit, page * limit);
				} catch (e: any) {
					sdk.notifier.error(`Failed to search customer groups on SFCC due to the error: ${e.message}`);
				}
			} else {
				try {
					customerGroupsResult = await apiClient.getCustomerGroups(limit, page * limit);
				} catch (e: any) {
					sdk.notifier.error(`Failed to get customer groups from SFCC due to the error: ${e.message}`);
				}
			}

			setCustomerGroupsResult(customerGroupsResult);
			setIsCustomerGroupsResultLoading(false);
		})();
	}, [sdk.notifier, apiClient, page, limit, query, siteId]);

	const onSearchCustomerGroupValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newCustomerGroupValue: string = e.target.value;

		setSearchCustomerGroupValue(newCustomerGroupValue);
		setPage(0);

		if (debounce) {
			clearTimeout(debounce);
		}

		debounce = setTimeout(() => {
			setQuery(newCustomerGroupValue);
		}, 300);
	};

	const onViewPerPageChange = (itemsPerPageNumber: number) => {
		setPage(Math.floor((limit * page + 1) / itemsPerPageNumber));
		setLimit(itemsPerPageNumber);
	};

	const changeApiCustomerGroup = (customerGroup: ICustomerGroup) => {
		setApiCustomerGroup(prevState => {
			if (prevState.some(group => group && group.id === customerGroup.id)) {
				const clone = [...prevState];

				clone.splice(prevState.findIndex(group => group && group.id === customerGroup.id), 1);

				return clone;
			} else {
				return [...prevState, customerGroup]
			}
		});
	};

	const renderCustomerGroups = () => {
		if (isCustomerGroupsResultLoading) {
			return <Spinner className="block-centered" customSize={150} />;
		} else {
			if (!customerGroupsResult?.total) {
				return <Text>No results found</Text>
			}

			return (
				<>
					<CustomerGroupsList
						customerGroups={customerGroupsResult?.data || customerGroupsResult?.hits || []}
						onCustomerGroupClick={changeApiCustomerGroup}
						selectedCustomerGroups={apiCustomerGroup}
					/>
					<Box marginTop="spacingM">
						<Pagination
							activePage={page}
							onPageChange={setPage}
							itemsPerPage={limit}
							totalItems={customerGroupsResult?.total || 0}
							showViewPerPage={!!customerGroupsResult && customerGroupsResult.total > 12}
							viewPerPageOptions={[12, 24, 48]}
							onViewPerPageChange={onViewPerPageChange}
						/>
					</Box>
				</>
			);
		}
	};

	return (
		<>
			<Modal.Header onClose={() => sdk.close()} title="Select Customer Group" />
			<Modal.Content>
				{!!siteListArray.length && <Box marginBottom="spacingM">
					<Text>Site for customer groups</Text>
					<Select
							id="siteId"
							name="siteId"
							value={siteId}
							className={css({marginBottom: '1rem'})}
							onChange={(e) => setSiteId(e.target.value)}
						>
							{siteListArray.map((availableSiteId: string) => (
								<Select.Option key={`site_option_${availableSiteId}`} value={availableSiteId}>
									{availableSiteId}
								</Select.Option>
							))}
					</Select>
				</Box>}
				<Box marginBottom="spacingM">
					<TextInput
						aria-label="Search by id or description"
						placeholder="Search by id or description"
						id="search-customer-group"
						value={searchCustomerGroupValue}
						onChange={onSearchCustomerGroupValueChange}
						icon={<SearchIcon />}
						isDisabled={!customerGroupsResult}
					/>
				</Box>
				{customerGroupsResult ? renderCustomerGroups() : <Spinner className="block-centered" customSize={150} />}
			</Modal.Content>
			{!isCustomerGroupsResultLoading && (
				<Modal.Controls className="dialog-btn-wrapper mb-0">
					<Button
						variant="primary"
						size="medium"
						isDisabled={!apiCustomerGroup.length}
						onClick={() => sdk.close(apiCustomerGroup)}
					>
						Select Customer Group
					</Button>
			</Modal.Controls>
			)}
		</>
	);
};

export default CustomerGroupDialog;
