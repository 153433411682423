import React from 'react';
import { EntityList } from '@contentful/f36-components';

import { ICustomerGroupsListProps } from './ICustomerGroupsList';
import { CustomerGroup } from './Subcomponents';

const CustomerGroupsList = ({ customerGroups, onCustomerGroupClick, selectedCustomerGroups }: ICustomerGroupsListProps) => {
	return (
		<EntityList className="list-scrollable">
			{customerGroups.map((customerGroup) => {
				return (
					<CustomerGroup
						key={customerGroup.id}
						customerGroup={customerGroup}
						onCustomerGroupClick={onCustomerGroupClick}
						selectedCustomerGroups={selectedCustomerGroups}
					/>
				);
			})}
		</EntityList>
	);
};

export default CustomerGroupsList;
