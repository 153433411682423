// @ts-nocheck

import OAuth2Token from '../classes/OAuth2Token';

import { IOpenCommerceAPI } from './IOpenCommerceAPI';

const cacheTime = 86400000; // 24h in Milliseconds

/**
 * A map for caching site-to-catalog mappings.
 * @type { Map }
*/
const cachedSiteToCatalogMapping = new Map();

/**
 * Represents OpenCommerceAPI
 */
class OpenCommerceAPI implements IOpenCommerceAPI {
	private config: any;
	private oauth2Token: OAuth2Token | null;

	/**
	 * @constructor
	 * @param {Object} config - config
	 */
	constructor(config: any) {
		this.config = config;
		this.oauth2Token = null;
	}

	/**
	 * Set Site id to be used
	 * @param {String} siteId - site id
	 */
	setSiteId(siteId) {
		if (siteId) {
			this.config.parameters.siteId = siteId;
		}
	}

	/**
	 * Retrieves site id
	 * @returns {String} - site id
	 */
	getSiteId() {
		return this.config?.parameters?.siteId;
	}

	/**
	 * Checks if client is correctly configured
	 * @returns {Boolean} - is valid
	 */
	isValid(): boolean {
		if (!this.config?.parameters?.clientId) {
			return false;
		}

		if (!this.config?.parameters?.clientSecret) {
			return false;
		}

		if (!this.config?.parameters?.domain) {
			return false;
		}

		if (!this.config?.parameters?.ocapiVersion) {
			return false;
		}

		if (!this.config?.parameters?.siteId) {
			return false;
		}

		if (!this.config?.parameters?.brandCode) {
			return false;
		}

		return true;
	}

	/**
	 * Retrieves access token
	 * @returns {String} - access token
	 */
	async getAccessToken(): Promise<string> {
		if (this.oauth2Token?.isValid()) {
			return this.oauth2Token.getAccessToken();
		}

		const queryParameters: URLSearchParams = new URLSearchParams({
			clientId: this.config.parameters.clientId,
			clientSecret: this.config.parameters.clientSecret,
		});
		const response = await fetch(`${this.config.proxy}/ocapi/token?${queryParameters}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const authTokenResponse = await response.json();

		if (authTokenResponse) {
			if ('error' in authTokenResponse) {
				throw new Error(`${authTokenResponse.error}: ${authTokenResponse.error_description}`);
			} else {
				this.oauth2Token = new OAuth2Token({
					accessToken: authTokenResponse.access_token,
					tokenType: authTokenResponse.token_type,
					refreshToken: authTokenResponse.refresh_token,
					expiresIn: authTokenResponse.expires_in,
				});

				return this.oauth2Token.getAccessToken();
			}
		}

		throw new Error(`Failed to retrieve token`);
	}

	/**
	 * Retrieves customer groups
	 * @param {Number} count - count
	 * @param {Number} start - offset
	 * @returns {ICustomerGroupResult} - customer groups
	 */
	async getCustomerGroups(count = 25, start = 0) {
		const accessToken: string = await this.getAccessToken();

		const queryParameters: URLSearchParams = new URLSearchParams({
			count,
			start,
			accessToken: accessToken,
			domain: this.config.parameters.domain,
			siteId: this.config.parameters.siteId,
			ocapiVersion: this.config.parameters.ocapiVersion,
		});
		const response = await fetch(`${this.config.proxy}/ocapi/customerGroups?${queryParameters}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const customerGroupsResponse = await response.json();

		if ('fault' in customerGroupsResponse) {
			throw new Error(customerGroupsResponse.fault.message);
		}

		return customerGroupsResponse;
	}

	/**
	 * Searches customer groups
	 * @param {String} query - query string to search by
	 * @param {Number} count - count
	 * @param {Number} start - offset
	 * @returns {ICustomerGroupResult} - customer groups search result
	 */
	async searchCustomerGroups(query = '', count = 25, start = 0) {
		const accessToken = await this.getAccessToken();

		const queryParameters = new URLSearchParams({
			query,
			count,
			start,
			accessToken: accessToken,
			domain: this.config.parameters.domain,
			siteId: this.config.parameters.siteId,
			ocapiVersion: this.config.parameters.ocapiVersion,
		});
		const response = await fetch(`${this.config.proxy}/ocapi/customerGroupSearch?${queryParameters}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const customerGroupSearchResponse = await response.json();

		if ('fault' in customerGroupSearchResponse) {
			throw new Error(customerGroupSearchResponse.fault.message);
		}

		return customerGroupSearchResponse;
	}


	/**
	 * Fetches the site-to-catalog mapping data
	 *
	 * @returns {Promise<object>} A Promise that resolves to the site-to-catalog mapping data.
	 * @throws {Error} If there is an error during the fetch operation or if the response contains a fault message.
	 */
	private fetchSiteToCatalogMapping = async () => {
		const accessToken = await this.getAccessToken();

		const queryParameters = new URLSearchParams({
			accessToken: accessToken,
			domain: this.config.parameters.domain,
			ocapiVersion: this.config.parameters.ocapiVersion,
		});

	   return fetch(
		   `${this.config.proxy}/ocapi/catalogs?${queryParameters}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
	   )
		   .then((response) => response.json())
		   .then((response) => {
				if ('fault' in response) {
					throw new Error(response.fault.message);
				}

			   return response || {};
		   }).catch((e) => {
			   console.error("An error occurred while obtaining information about catalogs", e);

			   return {};
		   });
	}

	/**
	 * Retrieves the catalog ID using the provided configuration and access token.
	 *
	 * @returns {Promise<string|null>} A promise that resolves to the catalog ID if found, or null if not found.
	 */
	async getCatalogId(siteId): Promise<string|null> {
		let catalogId = getSiteCatalogIdFromCache(siteId);

		if (!catalogId) {
			const siteToCatalogMapping = await this.fetchSiteToCatalogMapping();

			Object.keys(siteToCatalogMapping).forEach((currentSiteId) => {
				const currentCatalogId = siteToCatalogMapping[currentSiteId];

				setSiteCatalogIdToCache(currentSiteId, currentCatalogId);

				if (siteId === currentSiteId) {
					catalogId = currentCatalogId;
				}
			})
		}

		return catalogId;
	}

	/**
	 * Retrieves category
	 * @param {String} categoryId - category id
	 * @param {Number} levels - depth of subcategories to retrieve
	 * @returns {ICategory} - category
	 */
	async getCategory(categoryId: string = 'root', levels: number = 4): Promise<ICategory> {
		const accessToken = await this.getAccessToken();
		const siteId = this.config.parameters.siteId;

		const catalogId = await this.getCatalogId(siteId) || this.config.parameters.brandCode;

		const queryParameters = new URLSearchParams({
			accessToken: accessToken,
			catalogId: catalogId,
			domain: this.config.parameters.domain,
			siteId: siteId,
			ocapiVersion: this.config.parameters.ocapiVersion,
			categoryId: categoryId,
			levels: levels
		});
		const response = await fetch(`${this.config.proxy}/ocapi/catalogs/categories?${queryParameters}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const categoriesResponse = await response.json();

		if ('fault' in categoriesResponse) {
			throw new Error(categoriesResponse.fault.message);
		}

		return categoriesResponse;
	}
}


/**
 * Check if the cache time has expired based on the last updated timestamp.
 *
 * @param {number} lastUpdated - The timestamp when the cache was last updated.
 * @param {number} [validCacheTime] - The valid cache time in milliseconds. Defaults to the global `cacheTime`.
 * @returns {boolean} True if the cache has expired, otherwise false.
 */
const isCacheTimeExpired = (lastUpdated, cacheDuration = cacheTime) => {
	const currentTime = Date.now();

	// Check if the difference between the current time and lastUpdated is greater than the cache duration
	return (currentTime - lastUpdated) > cacheDuration;
}

/**
 * Get the site catalog ID from the cache.
 *
 * @param {string} siteId - The site ID for which to retrieve the catalog ID.
 * @returns {string|null} The cached catalog ID if available and not expired, or null if not found or expired.
 */
const getSiteCatalogIdFromCache = (siteId: string): string|null => {
	let catalogId = null;

	if (cachedSiteToCatalogMapping.has(siteId)) {
		const siteCatalog = cachedSiteToCatalogMapping.get(siteId);

		if (!isCacheTimeExpired(siteCatalog.lastUpdated)) {
			catalogId = siteCatalog.id;
		}
	}

	return catalogId;
}

/**
 * Get the site catalog ID from the cache.
 *
 * @param {string} siteId - The site ID for which to retrieve the catalog ID.
 * @returns {string|null} The cached catalog ID if available and not expired, or null if not found or expired.
 */
const setSiteCatalogIdToCache = (siteId: string, catalogId: string): void => {
	cachedSiteToCatalogMapping.set(siteId, {
		id: catalogId,
		lastUpdated: Date.now()
	})
}

export default OpenCommerceAPI;
