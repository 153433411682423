import React from 'react';
import { EntityList } from '@contentful/f36-components';

import { ICustomerGroupProps } from './ICustomerGroup';

const CustomerGroup = ({ customerGroup, onCustomerGroupClick, selectedCustomerGroups }: ICustomerGroupProps) => {
	const getListItemCssClasses = () => {
		let cssClasses = 'clickable-list-item';

		if (customerGroup && selectedCustomerGroups.some(group => group?.id === customerGroup.id)) {
			cssClasses += ' list-item-active';
		}

		return cssClasses;
	}

	return (
		<EntityList.Item
			key={customerGroup.id}
			title={customerGroup.id}
			withThumbnail={false}
			contentType={customerGroup.description || ''}
			onClick={() => onCustomerGroupClick(customerGroup)}
			className={getListItemCssClasses()}
		/>
	);
};

export default CustomerGroup;
