import { EntityList } from '@contentful/f36-components';

import { ICategoryProps } from './ICategory';

const Category = ({ category, onCategoryClick, selectedApiCategoryId }: ICategoryProps) => {
	const getListItemCssClasses = () => {
		let cssClasses = 'clickable-list-item clickable-list-item--centered';

		if (selectedApiCategoryId === category.id) {
			cssClasses += ' list-item-active';
		}

		return cssClasses;
	};

	return (
		<EntityList.Item
			key={category.id}
			title={category.name?.default || ''}
			contentType={category.id}
			withThumbnail={false}
			thumbnailUrl={''}
			status={category.online ? 'published' : 'archived'}
			onClick={() => {
				if (category.online) {
					onCategoryClick(category)
				}
			}}
			className={getListItemCssClasses()}
		/>
	);
};

export default Category;
