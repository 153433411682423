import { IOAuth2Token, IOAuth2TokenData } from './IOAuth2Token';

/**
 * Represents OAuth2Token
 */
class OAuth2Token implements IOAuth2Token {
	private accessToken: string;
	private tokenType: string;
	private refreshToken: string;
	private expirationDateTime: Date;

	/**
	 * @constructor
	 * @param {IOAuth2TokenData} data - initialization data
	 * @param {String} data.accessToken - access token
	 * @param {String} data.tokenType - token type
	 * @param {String} [data.refreshToken] - refresh token
	 * @param {Number} [data.expiresIn] - expires in (in seconds)
	 */
	constructor(data: IOAuth2TokenData) {
		this.accessToken = data.accessToken;
		this.tokenType = data.tokenType;
		this.refreshToken = data.refreshToken || '';
		this.expirationDateTime = getExpirationDateTime(data.expiresIn);
	}

	/**
	 * Returns access token
	 * @returns {String} - access token
	 */
	getAccessToken(): string {
		return this.accessToken;
	}

	/**
	 * Returns token type
	 * @returns {String} - access token type
	 */
	getTokenType(): string {
		return this.tokenType;
	}

	/**
	 * Returns refresh token
	 * @returns {String|undefined} - refresh token
	 */
	getRefreshToken(): string | undefined {
		return this.refreshToken;
	}

	/**
	 * Returns expiration date time
	 * @returns - expiration date time
	 */
	getExpirationDateTime(): Date {
		return this.expirationDateTime;
	}

	/**
	 * Checks if token is valid
	 * @returns {Boolean} - validation result
	 */
	isValid(): boolean {
		return this.expirationDateTime > new Date();
	}
}

/**
 * Returns expiration DateTime
 * @param {Number|undefined} [expiresIn] - expires in (in seconds)
 * @returns {Date} - expiration Date
 */
function getExpirationDateTime(expiresIn: number | undefined): Date {
	if (expiresIn) {
		return new Date(Date.now() + expiresIn * 1000);
	}

	return new Date(Date.now() - 1);
}

export default OAuth2Token;
