// @ts-nocheck

import React from 'react';
import { EntryCard, MenuItem } from '@contentful/f36-components';

import { ICategoryProps } from './ICategory';

const Category = ({ category, onCategorySelect, onCategoryRemove, withDragHandle, dragHandle }: ICategoryProps) => {
	const renderThumbnailElement = () => {
		if (!category.image) {
			return null as any;
		}

		return <img alt={category.name?.default} title={category.name?.default} src={category.image} />;
	};

	return (
		<EntryCard
			contentType={category.id}
			title={category.name?.default}
			onClick={() => onCategorySelect(category)}
			actions={[
				<MenuItem key="remove" onClick={() => onCategoryRemove(category)}>
					Remove
				</MenuItem>,
			]}
			thumbnailElement={renderThumbnailElement()}
			withDragHandle={withDragHandle}
			padding={withDragHandle ? 'default' : 'none'}
			dragHandleRender={() => (withDragHandle ? dragHandle : undefined)}
		/>
	);
};

export default Category;
